import React from "react";
import Connecteur from '../../components/Connecteur';
import DmpLogo from '../../media/connecteurs/dmp-logo.png';

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "dmp",
      title: "Dossier médical partagé",
      description: "Un connecteur facile à envoyer et consulter les données du DMP.",
      content:
      <div>
        <div class="columns section-panel">
            <div class="column alt-column is-8">
                <div class="content is-medium">
                    <h2>Le dossier médical partagé</h2>
                    <p>Le Dossier Médical Partagé (DMP) est le carnet de santé numérique français qui conserve et sécurise les informations de santé des patients: traitements, résultats d'examens, allergies… Il permet de partager ces informations avec les professionnels de santé qui en ont besoin mais peut également permettre aux patients de stocker leurs informations médicales de manière sécurisée.</p>
                    <p>Ce connecteur est en cours de développement mais n'hésitez pas à nous contacter si vous souhaitez faire parti des bêta testeurs !</p>
                </div>
            </div>
            <div class="column alt-column is-2 is-offset-1">
                <figure class="image">
                  <img src={ DmpLogo } alt="Logo du DMP" />
                </figure>
                <a class="button is-danger" href="../../contact">On discute ?</a>
            </div>
        </div>
      </div>

    };
  }

  render() {
    return (
        <Connecteur 
            content={this.state.content} 
            activePage={this.state.page} 
            title={this.state.title}
            description={this.state.description}
        />
    );
  }
}
